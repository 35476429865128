import React, { useState, createContext, useContext } from 'react';

const initialSiteState = {
  navOpen: false,
  headerDark: false,
  headerIsPinned: false,
  myPorter: false,
  showBriefUs: false,
  formIsActive: false,
  globalForm: null,
  footerForms: false,
  footerTheme: 'black',
  footerHidden: false,
  projectsEditorial: true,  
  pageBackground: 'white',
  pageFeatureColor: null,
  showAuthModal: false,
  user: null,
  cartProject: null,
  cartItems: null,
  cartEditItem: null,
  productGridLarge: false,
  featuredProjectHover: false,
};

const SiteContext = createContext({initialSiteState});

const SiteContextProvider = ({ children }) => {
  const [pageBackground, setPageBackground] = useState(initialSiteState.pageBackground);  
  const [navOpen, setNavOpen] = useState(initialSiteState.navOpen);  
  const [footerHidden, setFooterHidden] = useState(initialSiteState.footerHidden);  
  const [headerDark, setHeaderDark] = useState(initialSiteState.headerDark);  
  const [headerIsPinned, setHeaderIsPinned] = useState(initialSiteState.headerIsPinned);  
  const [pageFeatureColor, setPageFeatureColor] = useState(initialSiteState.pageFeatureColor);  
  const [projectsEditorial, setProjectsEditorial] = useState(initialSiteState.projectsEditorial);  
  const [formIsActive, setFormIsActive] = useState(initialSiteState.formIsActive);  
  const [globalForm, setGlobalForm] = useState(initialSiteState.globalForm);  
  const [footerForms, setFooterForms] = useState(initialSiteState.footerForms);  
  const [footerTheme, setFooterTheme] = useState(initialSiteState.footerTheme);  
  const [myPorter, setMyPorter] = useState(initialSiteState.myPorter);  
  const [showingBriefUs, setShowingBriefUs] = useState(initialSiteState.showBriefUs);  
  const [showAuthModal, setShowAuthModal] = useState(initialSiteState.showAuthModal);  
  const [user, setUser] = useState(initialSiteState.user);  
  const [cartProject, setCartProject] = useState(initialSiteState.cartProject);  
  const [cartItems, setCartItems] = useState(initialSiteState.cartItems);  
  const [cartEditItem, setCartEditItem] = useState(initialSiteState.cartEditItem);  
  const [productGridLarge, setProductGridLarge] = useState(initialSiteState.productGridLarge);  
  const [featuredProjectHover, setFeaturedProjectHover] = useState(initialSiteState.featuredProjectHover);  
    return (
      <SiteContext.Provider
        value={{
          pageBackground,                              
          setPageBackground,
          navOpen,
          setNavOpen,
          footerHidden,
          setFooterHidden,
          headerDark,
          setHeaderDark,
          headerIsPinned, 
          setHeaderIsPinned,
          pageFeatureColor, 
          setPageFeatureColor,
          projectsEditorial, 
          setProjectsEditorial,
          footerForms,
          setFooterForms,
          footerTheme,
          setFooterTheme,
          myPorter,
          setMyPorter,
          showingBriefUs,
          setShowingBriefUs,
          showAuthModal, 
          setShowAuthModal,
          user, 
          setUser,
          cartProject, 
          setCartProject,
          cartItems, 
          setCartItems,
          cartEditItem, 
          setCartEditItem,
          productGridLarge, 
          setProductGridLarge,
          formIsActive, 
          setFormIsActive,
          globalForm, 
          setGlobalForm,
          featuredProjectHover, 
          setFeaturedProjectHover,
        }}        
      >
        {children}
      </SiteContext.Provider> 
    );
};

function useSiteState() {
    const {
        pageBackground,  
        setPageBackground,   
        navOpen,
        setNavOpen,
        footerHidden,
        setFooterHidden,
        headerDark,
        setHeaderDark,
        headerIsPinned, 
        setHeaderIsPinned,
        projectsEditorial, 
        setProjectsEditorial,
        pageFeatureColor, 
        setPageFeatureColor,
        footerForms,
        setFooterForms,   
        footerTheme,
        setFooterTheme,
        myPorter,
        setMyPorter,
        showingBriefUs,
        setShowingBriefUs,
        showAuthModal, 
        setShowAuthModal,
        user, 
        setUser,
        cartProject, 
        setCartProject,
        cartItems, 
        setCartItems,
        cartEditItem, 
        setCartEditItem,
        productGridLarge, 
        setProductGridLarge,
        formIsActive, 
        setFormIsActive,
        globalForm, 
        setGlobalForm,
        featuredProjectHover, 
        setFeaturedProjectHover,
    } = useContext(SiteContext);    
    
        
    return {
      pageBackground, 
      setPageBackground,
      navOpen,
      setNavOpen,
      footerHidden,
      setFooterHidden,
      headerDark,
      setHeaderDark,
      headerIsPinned, 
      setHeaderIsPinned,
      projectsEditorial, 
      setProjectsEditorial,
      pageFeatureColor, 
      setPageFeatureColor,
      footerForms,
      setFooterForms,  
      footerTheme,
      setFooterTheme,
      myPorter,
      setMyPorter,
      showingBriefUs,
      setShowingBriefUs,
      showAuthModal, 
      setShowAuthModal,
      user, 
      setUser,
      cartProject, 
      setCartProject,
      cartItems, 
      setCartItems,
      cartEditItem, 
      setCartEditItem,
      productGridLarge, 
      setProductGridLarge,
      formIsActive, 
      setFormIsActive,
      globalForm, 
      setGlobalForm,
      featuredProjectHover, 
      setFeaturedProjectHover,
    };
}
  
  export { useSiteState, SiteContextProvider };
  