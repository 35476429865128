exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-account-orders-js": () => import("./../../../src/pages/account/orders.js" /* webpackChunkName: "component---src-pages-account-orders-js" */),
  "component---src-pages-account-profile-js": () => import("./../../../src/pages/account/profile.js" /* webpackChunkName: "component---src-pages-account-profile-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-packaging-js": () => import("./../../../src/pages/packaging.js" /* webpackChunkName: "component---src-pages-packaging-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-client-page-js": () => import("./../../../src/templates/clientPage.js" /* webpackChunkName: "component---src-templates-client-page-js" */),
  "component---src-templates-packaging-product-builder-js": () => import("./../../../src/templates/packagingProductBuilder.js" /* webpackChunkName: "component---src-templates-packaging-product-builder-js" */),
  "component---src-templates-packaging-product-js": () => import("./../../../src/templates/packagingProduct.js" /* webpackChunkName: "component---src-templates-packaging-product-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-resource-page-js": () => import("./../../../src/templates/resourcePage.js" /* webpackChunkName: "component---src-templates-resource-page-js" */)
}

