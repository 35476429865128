import React from 'react';
import { SiteContextProvider } from './src/lib/siteContext';
import { AnimatePresence } from 'framer-motion';

export const wrapRootElement = ({ element }) => (
    <SiteContextProvider>{element}</SiteContextProvider>
  );
  
  export function wrapPageElement({ element, props }) {
    return <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
  }
  // export const wrapPageElement = ({ element }) => (
  //   <AnimatePresence
  //     exitBeforeEnter
  //     onExitComplete={() => window.scrollTo({
  //       top: 0,
  //       left: 0,
  //       behavior: "instant"
  //     })}
  //   >
  //     {element}
  //   </AnimatePresence>
  // );
  

  const transitionDelay = 300;

  export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    if (location.action === 'PUSH') {
      window.setTimeout(() => window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant"
      }), transitionDelay);
    } else if (location.action !== 'POP') {
      const savedPosition = getSavedScrollPosition(location);
      window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay);
    }
    return false;
  };
  